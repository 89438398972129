#ExternalData .externalBox:first-child {
    background-color: #21b42f;
}


#ExternalData .externalBox:nth-child(2) {
    background-color: #8833ff;
}

#ExternalData .externalBox:nth-child(3) {
    background-color: #ff6633;
}

#ExternalData .externalBox:nth-child(4) {
    background-color: #33bfff;
}

#ExternalData .externalBox:nth-child(5) {
    background-color: #1a2233;
}

.internalDataCard {
    background-image: url(./assets/new-dashboard-img/map.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    background-position: center right;
}
